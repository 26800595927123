<template>
  <div>
    <v-dialog v-model="dialog" eager persistent scrollable fullscreen>
      <v-card>
        <v-card-title dark class="dialog-header pt-2 pb-2 pl-6">
          <span>Employee CTO Card asdasd</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeD()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text style="max-height: 900px" class="my-4">
          <v-container>
            <v-row>
              <v-col cols="12"
                ><div class="d-flex">
                  <div style="width:20%" class="text-subtitle-1">Name:</div>

                  <div
                    style="width:80%"
                    class="text-subtitle-1 font-weight-bold"
                  >
                    {{ name }}
                  </div>
                </div>
              </v-col>
              <!-- <v-col cols="6"
                ><div class="d-flex">
                  <div style="width:20%" class="text-subtitle-1">Name:</div>

                  <div style="width:80%" class="text-subtitle-1">
                    {{ name }}
                  </div>
                </div>
              </v-col> -->
              <v-col cols="12">
                <table class="table mt-5 mx-2">
                  <thead>
                    <tr>
                      <th
                        align="left"
                        class="text-caption px-4 font-weight-bold py-2"
                      >
                        Date Earned
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        No. of Hours<br />
                        Earned COCs
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        Equivalent No. of Hours<br />
                        for CTO
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        Date of CTO
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        Used COCs<br />(in hours)
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        Remaining COCs <br />(in hours)
                      </th>
                      <th
                        align="center"
                        class="text-caption font-weight-bold py-2"
                      >
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="hasCTOCardData">
                    <tr v-for="(item, i) in cto_card_data" :key="i">
                      <td align="left" class="text-caption pa-2 ">
                        {{ item.date_earned }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ item.coc_hours }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ item.cto_hours }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ item.cto_date }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ item.cto_used }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ formatNumberValue(item.cto_rem, 2) }}
                      </td>
                      <td align="center" class="text-caption py-2">
                        {{ item.remarks }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="!hasCTOCardData">
                    <tr>
                      <td
                        colspan="7"
                        align="center"
                        class="text-caption pa-4 grey--text"
                      >
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions class="pa-5" v-if="viewFrom != 'audit'">
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            v-if="!openDeduct"
            @click="deductDialog()"
            color="#6DB249"
          >
            Deduct Expired
          </v-btn>

          <v-btn v-if="!openDeduct" color="red" outlined @click="closeD()">
            Close
          </v-btn>

          <v-btn v-if="openDeduct" color="red" outlined @click="cancelDeduct()">
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation -->
    <v-dialog v-model="confirmDialog" persistent max-width="400">
      <v-card color="white">
        <div class="pa-4 #3a3b3a--text">
          <div class="text-h6 mb-1">WARNING!</div>

          <!-- error message-->
          <div class="text-body-1 mb-1">
            <p style="text-align: justify">
              <v-icon class="mt-n2" color="white">mdi-alert</v-icon> &nbsp; Are
              you sure you want to restore this leave credits?
            </p>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="confirmDialog = false">
            Close
          </v-btn>

          <v-btn
            color="green"
            class="white--text"
            :loading="submitBtnLoading"
            :disabled="submitBtnLoading"
            @click="restore()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openDeductDialog" persistent max-width="700">
      <v-card color="white">
        <v-card-title dark class="dialog-header">
          Deduct Expired CTO
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeD()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 800px">
          <v-form ref="deductFormRef" @submit.prevent>
            <v-container>
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-text-field
                    :rules="[formRules.required]"
                    dense
                    outlined
                    v-model="cto_left"
                    required
                    label="CTO credits"
                    class="rounded-lg"
                    color="#6DB249"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    dense
                    outlined
                    class="rounded-lg"
                    color="#6DB249"
                    :rules="[formRules.required]"
                    v-model="exp_remarks"
                    required
                    label="Remarks"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="openDeductDialog = false">
            Close
          </v-btn>

          <v-btn
            color="green"
            class="white--text"
            :loading="submitBtnLoading"
            :disabled="submitBtnLoading"
            @click="add_expire()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  props: {
    data: null,
    action: null,
    viewFrom: null,
  },
  data() {
    return {
      dialog: false,
      submitBtnLoading: false,
      confirmDialog: false,
      openDeductDialog: false,
      cto_left: null,
      exp_remarks: null,
      openDeduct: null,
      itemToRestore: null,
      hasCTOCardData: null,
      cto_card_data: [],
      id: null,
      empID: null,
      name: null,
      filter: null,
      fadeAwayMessage: {
        show: false,
        type: "success",
        header: "Successfully Added!",
        message: "",
        top: 10,
      },
    };
  },

  watch: {
    data: {
      handler(data) {
        this.dialog = true;
        console.log("CTO", data);
        this.name = data.name;
        this.empID = data.empID;
        this.filter = this.$store.getters.getFilterSelected;
        this.loadCTOCard();
      },
      deep: true,
    },
  },

  methods: {
    computeAnnual(value) {
      var annual = 0;
      for (let index = 0; index < 12; index++) {
        annual += parseFloat(value);
      }
      return annual;
    },

    deductDialog() {
      let crd = this.formatNumberValue(
        this.cto_card_data[this.cto_card_data.length - 1].cto_rem,
        2
      );
      this.cto_left = crd;
      this.openDeduct = true;
      this.openDeductDialog = true;
    },
    cancelDeduct() {
      this.openDeduct = false;
      this.openDeductDialog = false;
    },

    loadCTOCard() {
      this.axiosCall(
        "/leave-credits/getCTOCardByEmpID/" + this.empID + "/" + this.filter,
        "GET"
      ).then((res) => {
        if (res) {
          this.hasCTOCardData = res.data.length > 0;
          this.cto_card_data = res.data;
        }
      });
    },

    add_expire() {
      if (this.$refs.deductFormRef.validate()) {
        if (parseFloat(this.cto_left) > 0) {
          let data = {
            empID: this.empID,
            cto_left: this.cto_left,
            remarks: this.exp_remarks,
          };
          this.axiosCall("/leave-credits/deductExpireCTO", "POST", data).then(
            (res) => {
              if (res.data.status == 201) {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = res.data.msg;
                this.openDeductDialog = false;
                this.openDeduct = false;
                this.loadCTOCard();
              } else if (res.data.status == 400) {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = res.data.msg;
              }
            }
          );
        }
      }
    },

    closeD() {
      this.dialog = false;
      this.openDeductDialog = false;
      this.openDeduct = false;
      this.eventHub.$emit("closeViewCTOCardDialog", false);
    },
  },
};
</script>
<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}
table,
td {
  border-bottom: 1px solid rgb(206, 202, 202);
}
tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

/* tr:nth-child(2) th:first-child {
  border-top-left-radius: none;
}
tr:nth-child(2) th:last-child {
  border-top-right-radius: none;
} */

tr td:first-child {
  border-left: 1px solid rgb(206, 202, 202);
}
tr td:last-child {
  border-right: 1px solid rgb(206, 202, 202);
}
</style>
